<template>
  <div>
    <div class="detailContent">
      <!-- 应用信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.AppInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.app.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationVersion')+'：'}}</span>
            <span class="detaildesc">{{ detailData.app.version }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.AppPackageName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.app.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{$t('SecurityMonitor.Sensitivebehavior.AppMD5')+'：'}}</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.app.md5 }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.BasicInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('public.DeviceName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.devicename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.Name')+'：'}}</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.EquipmentModel')+'：'}}</span>
            <span class="detaildesc">{{ detailData.device.model }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.SystemVersion')+'：'}}</span>
            <span class="detaildesc">{{ detailData.device.osversion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.DeviceIP')+'：'}}</span>
            <span class="detaildesc">{{ detailData.device.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('public.Username')+'：'}}</span>
            <span class="detaildesc">{{ detailData.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.UserDepartment')+'：'}}</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.groupname.substr(groupFullNameShowByIndex) }}</span>
          </div>
          <div class="group">
                         <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
                         <span v-if="$common.getSystemName(detailData.osVersion)=='Android'" class="detailLabel"  style="vertical-align: top;"
                         >{{ $t("public.IMEIAndroid") }}：</span
                       >
                       <span v-if="$common.getSystemName(detailData.osVersion)=='iOS'" class="detailLabel"  style="vertical-align: top;"
                         >{{ $t("public.IMEIiOS") }}：</span
                       >
                       <span v-if="$common.getSystemName(detailData.osVersion)=='Linux'" class="detailLabel"  style="vertical-align: top;"
                         >{{ $t("public.IMEIMac") }}：</span
                       >
                       <span v-if="$common.getSystemName(detailData.osVersion)=='Windows'" class="detailLabel"  style="vertical-align: top;"
                         >{{ $t("public.IMEIMac") }}：</span
                       >
                       <span v-if="$common.getSystemName(detailData.osVersion)=='Unknown'" class="detailLabel"  style="vertical-align: top;"
                         >{{ $t("public.IMEIUnknown") }}：</span
                       >
            <span class="detaildesc" style="vertical-align: top;" v-if="detailData.device.imei == ''">N/A</span>
            <span class="detaildesc" style="vertical-align: top;" v-else>{{ detailData.device.imei }}</span>
          </div>
          <div class="group" style="display: flex;">
            <span class="detailLabel" style="display: block;">{{$t('public.InternetMethod')+'：'}}</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ $filter.surfInterType(detailData.device.nettype)
            }}</span>
          </div>
        </div>
      </div>
      <!-- <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.BasicInfo')" :column="2">
        <el-descriptions-item :label="$t('public.DeviceName')">{{ detailData.devicename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Username')">{{ detailData.loginname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Name')">{{ detailData.username }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.UserDepartment')">{{ detailData.groupname.substr(groupFullNameShowByIndex) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentModel')">{{  detailData.device.model }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentIMEI')">{{ detailData.device.imei  }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.deviceManufacturer')">{{ detailData.operator }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.ComStatus')">{{ detailData.isDanger }}</el-descriptions-item> 
        <el-descriptions-item :label="$t('public.SystemVersion')">{{ detailData.device.osversion }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.InternetMethod')">{{ $filter.surfInterType(detailData.device.nettype) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.DeviceIP')">{{ detailData.device.ip }}</el-descriptions-item>
      </el-descriptions> -->
      <!-- 位置信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.PositionInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Country')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.locationinfo == 'N/A'">N/A</span>
            <span class="detaildesc" v-else>{{ detailData.locationinfo.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.City')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.locationinfo == 'N/A'">N/A</span>
            <span class="detaildesc" v-else>{{ detailData.locationinfo.city }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Province')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.locationinfo == 'N/A'">N/A</span>
            <span class="detaildesc" v-else>{{ detailData.locationinfo.province }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.LatitudeLongitude')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.device.longitude == '' || detailData.longitude == 'N/A' || detailData.longitude == null">N/A</span>
            <span class="detaildesc" v-else>{{ detailData.device.longitude }},{{ detailData.device.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Address')+'：'}}</span>
          <span class="detaildesc" v-if="detailData.locationinfo == 'N/A'">N/A</span>
          <span class="detaildesc" v-else>{{ detailData.locationinfo.locationStr }}</span>
        </div>
      </div>
      <!-- 病毒信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.VirusInfo.VirusInformation')+'：'}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('public.GenerationTime')+'：'}}</span>
            <span class="detaildesc">{{
              detailData.time
            }}</span>
          </div>
        </div>
        <div class="boxRight">

          <div class="group">
            <span class="detailLabel">{{$t('public.ReportingTime')+'：'}}</span>
            <span class="detaildesc">{{
              detailData.uploadtime
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div class="detailLabel" style="float: left;
                    height: 24px;
                    line-height: 24px;
                    text-align: left;
                    pading-right: 5px;
                    width:13%;
                  ">
            <span>{{$t('SecurityMonitor.VirusInfo.DetailedInfo')+'：'}}</span>
          </div>
          <div style="
                   float: left;
                   width: 78%;
                   height: inherit;
                   text-align: left;
                   padding-right: 5px;
                  " class="detaildesc" v-if="detailData.virusescount == 0">
            <p style="
                     width: 100%;
                     display: inline-block;
                     overflow: hidden;
                     color: #807e7e;
                     margin-left: 0;
                     line-height: 24px;
                     word-wrap: break-word;
                     word-break: break-all;
                     color:#606266
                    ">
    {{$t('SecurityMonitor.VirusInfo.NoVirus')}}
            </p>
          </div>
          <div v-else style="
                   float: left;
                   width: 78%;
                   height: inherit;
                   text-align: left;
                   padding-right: 5px;
                  " class="detaildesc">
            <p v-for="(e, i) in detailData.decide" :key="i" style="
                     width: 100%;
                     display: inline-block;
                     overflow: hidden;
                     color: #807e7e;
                     margin-left: 0;
                     line-height: 24px;
                     word-wrap: break-word;
                     word-break: break-all;
                    color:#606266
                    ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'detail',

  props: {
    detailData: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      groupFullNameShowByIndex: '',
    }
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;

  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;

    .boxLeft {
      width: 50%;
      margin-left: 68px;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 44%;
          text-align: left;
        }

        .detaildesc {
          width: 56%;
        }
      }
    }

    .boxRight {
      width: 50%;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 23%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }

  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;

    .group {
      margin-top: 8px;

      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }

      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}

.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
::v-deep .el-descriptions__title {
  color: #606266 !important;
  font-size: 14px !important;
}
::v-deep .el-descriptions__body {
  color: #606266 !important;
  font-size: 12px !important;
  background-color: #fff !important;
  padding-left: 70px !important;
}
</style>